import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';

class Soft_Box_About extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let imgattr = 'image';
        let data = sectiondata.softboxabout;
        const inlineStyle = {
            backgroundImage: 'url(' + publicUrl + sectiondata.softboxabout.bgimg + ')'
        };

        return <div id="clients">
            <div className=" mg-top-170">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-10">
                        <div className="section-title text-center margin-bottom-90">
                            <h2 className="title wow animated fadeInUp" data-wow-duration="0.6s"
                                data-wow-delay="0.1s">
                                Our
                                <span> Work</span>
                                <p>{data.subtitle}</p>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sbs-what-riyaqas pd-default-120" style={inlineStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 desktop-center-item">
                            <div className="desktop-center-area wow animated fadeInLeft" data-wow-duration="1s"
                                 data-wow-delay="0.3s">
                                <div className="section-title style-two">
                                    <a href={data.url} target="_blank">
                                        <h2 className="title">
                                            {data.sectiontitle}
                                            <span>{data.sectiontitle_color}</span>
                                        </h2>
                                    </a>
                                    <p><a href={data.url} target="_blank" style={{margin: "auto"}}>Inxelo Technologies</a> is a company working on aviation management software solutions. It's one of the most interesting and rewarding projects we have worked on as we learned a lot about aircraft, pilots, airdromes, and aviation in general. I would be lying by saying it was easy since there are tons of regulations - your software need to keep up. It was surprisingly complex but challenging and fun.</p>
                                </div>
                                <div className="row">
                                    {data.list_item.map((item, i) =>
                                        <div key={i} className="col-md-6">
                                            <div className="riyaqas-check-list">
                                                <img src={publicUrl + item.icon} alt={imgattr}/>
                                                <span>{item.text}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
                            <img src={publicUrl + data.imgurl} alt={imgattr}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Soft_Box_About