import React from 'react';
import Navbar from './global-components/navbar';
import Banner from "./section-components/banner";
import Footer from "./global-components/footer";
import SoftBoxAbout from "./section-components/soft-box-about";
import SoftBoxAbout2 from "./section-components/soft-box-about-2";
import Testimonial from "./section-components/testimonial";
import ContactForm from "./section-components/contact-form";
import Services from "./section-components/services";

class Home extends React.Component {
    render() {
        return <div>
            <Navbar/>
            <Banner/>
            <Services customclass="pd-top-112"/>
            <SoftBoxAbout/>
            <SoftBoxAbout2/>
            <Testimonial/>
            <ContactForm/>
            <Footer/>
        </div>
    }
}

export default Home


