import React, {Component} from 'react';
import footerdata from '../../data/footerdata.json';
import {Link} from 'react-scroll';

class Footer extends Component {

    componentDidMount() {
        let publicUrl = process.env.PUBLIC_URL + '/';

        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/';
        let imgAlt = 'footer logo';

        return (
            <div>
                <footer className="footer-area footer-area-2">
                    <div className="container">
                        <div className="footer-widget-area mg-top-120">
                            <div className="row">
                                <div className="col-lg-4"/>
                                <div className="col-lg-4">
                                    <div className="footer-widget widget ">
                                        <h4 className="widget-title">{footerdata.contactwidget.title}</h4>
                                        <div className="contact_info_list">
                                            <p className="contact-content">{footerdata.contactwidget.address}</p>
                                            <p><span>E-mail:</span> {footerdata.contactwidget.email}</p>
                                            <p>
                                                <div className="social-icon">
                                                    <li style={{margin: "10px -5px"}}>
                                                        <a className="facebook"
                                                           href={"https://www.linkedin.com/company/prudentology"}
                                                           target="_blank"
                                                           rel="noopener noreferrer">
                                                            <i className={"fa fa-linkedin"}/>
                                                        </a>
                                                    </li>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-widget widget widget_nav_menu">
                                        <h4 className="widget-title">{footerdata.quicklink.title}</h4>
                                        <ul className="riyaqas-nav">
                                            <li>
                                                <Link activeClass="active" to={'home'} spy={true} smooth={true}
                                                      duration={500}>Home</Link>
                                            </li>
                                            <li>
                                                <Link activeClass="active" to={'services'} spy={true} smooth={true}
                                                      duration={500} offset={-50}>Services</Link>
                                            </li>
                                            <li>
                                                <Link activeClass="active" to={'clients'} spy={true} smooth={true}
                                                      duration={500} offset={-150}>Clients</Link>
                                            </li>
                                            <li>
                                                <Link activeClass="active" to={'contact'} spy={true} smooth={true}
                                                      duration={500}>Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up"/></span>
                </div>
            </div>
        )
    }
}


export default Footer