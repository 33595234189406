import React from 'react';
import Player from './player';
import playersData from './players-data';
import Navbar from '../global-components/navbar';
import Banner from "../section-components/banner";
import Footer from "../global-components/footer";
import SoftBoxAbout from "../section-components/soft-box-about";
import SoftBoxAbout2 from "../section-components/soft-box-about-2";
import Testimonial from "../section-components/testimonial";
import ContactForm from "../section-components/contact-form";
import Services from "../section-components/services";

class PlayerList extends React.Component {

    componentDidMount() {
      let publicUrl = process.env.PUBLIC_URL + '/';

      const minscript = document.createElement("script");
      minscript.async = true;
      minscript.src = publicUrl + "assets/js/main.js";

      document.body.appendChild(minscript);
    }

    render() {
        return <div>
            <div >
              <div className="team-area-wrpper about-team-area-wrpper ">
                  <div className="container">
                      <div className="row justify-content-center">
                          <div className="col-xl-6 col-lg-10">
                              <div className="section-title text-center">
                                  <h2 className="title">Jadranovo turnir <span>2023</span></h2>
                                  <p>Ekipa “Prudentology” mira nikad nema, ove godine pakleno izdanje sprema. Selekcije mnoge vec su prošle, a neke nove zvijezde su došle. Spremni su pokoriti sve pred sobom i protivnika ispratiti pozdravom “zbogom”! Termine jos nikad nije igrala srijedom, ali bio bi red predstaviti ih redom.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="team-area text-center">
                      <div className="container">
                          <div className="row ">
                          {playersData.map(player => (
                            <Player
                              key={player.id}
                              name={player.name}
                              image={player.image}
                              description={player.description}
                              position={player.position}
                            />
                          ))}
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    }
}

export default PlayerList
