import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';

class Soft_Box_About_2 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let imgattr = 'image';
        let data = sectiondata.softboxabout2;
        const inlineStyle = {
            backgroundImage: 'url(' + publicUrl + data.bgimg + ')',
            backgroundPosition: 'right'
        };

        return <div>
            <div className="sbs-what-riyaqas pd-default-120 mg-top-105" style={inlineStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                            <img src={publicUrl + data.imgurl} alt={imgattr}/>
                        </div>
                        <div className="col-xl-5 col-lg-5 desktop-center-item">
                            <div className="desktop-center-area wow animated fadeInLeft" data-wow-duration="1s"
                                 data-wow-delay="0.3s">
                                <div className="section-title style-two">
                                    <a href={data.url} target="_blank">
                                        <h2 className="title">
                                            {data.sectiontitle}
                                            <span>{data.sectiontitle_color}</span>
                                            {data.sectiontitle2}
                                        </h2>
                                    </a>
                                    <p><a href={data.url} target="_blank" style={{margin: "auto"}}>CAT</a> is a fast-growing company from Dubai working on many different projects. We've been included in some of them as a part of a mobile team. The products are from various domains like a social media app, educational platform, and E-commerce. People included are from all corners of the world and the projects are dynamic and challenging.</p>
                                </div>
                                <div className="row">
                                    {data.list_item.map((item, i) =>
                                        <div key={i} className="col-md-6">
                                            <div className="riyaqas-check-list">
                                                <img src={publicUrl + item.icon} alt={imgattr}/>
                                                <span>{item.text}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Soft_Box_About_2