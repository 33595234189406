import React, {Component} from 'react';
import emailjs from "emailjs-com";

const SERVICE_ID = 'gmail';
const TEMPLATE_ID = 'template_3wlnmlh';
const USER_ID = 'user_Q1UqxKFvbGPbaOLXY6rlW';

class Contact_Form extends Component {

    sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        window.showLoading();

        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
            .then((result) => {
                document.getElementById("submit_form").reset();
                console.log(`Email sent: ${result.status} ${result.text}`);
                // window.location.reload();  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
                window.hideLoading();
            }, (error) => {
                document.getElementById("submit_form").reset();
                console.log(error.text);
                window.hideLoading();
            });
    }

    render() {
        let imgalt = 'image';
        let publicUrl = process.env.PUBLIC_URL + '/';

        return <div id="contact">
            <div className="contact-form-area pd-top-170">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title text-center w-100">
                                <h2 className="title">How can we <span>Help?</span></h2>
                                <p>Have a question or business proposition, or maybe just want to say hi. Feel free to
                                    send a message. We are looking forward to hearing from you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-5">
                            <img src={publicUrl + 'assets/img/others/21.png'} alt={imgalt}/>
                        </div>
                        <div className="col-lg-7 offset-xl-1">
                            <form id="submit_form" className="riyaqas-form-wrap mt-5 mt-lg-0"
                                  onSubmit={e => this.sendEmail(e)}>
                                <div className="row custom-gutters-16">
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input" name="name"/>
                                            <label>Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input" name="email"/>
                                            <label>E-mail</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input-wrap">
                                            <input type="text" className="single-input" name="subject"/>
                                            <label>Subject</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input-wrap">
                                            <textarea className="single-input textarea" cols="20" name="message"/>
                                            <label className="single-input-label">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <input className="btn btn-red mt-0" type="submit" value="Send"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Contact_Form

