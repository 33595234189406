import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';

class Testimonial extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/';
        let data = sectiondata.testimonials;

        return <div>
            <div className="sbst-testimonial-area mg-top-40">
                <div className="container">
                    <div className="testimonial-slider2-bg">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="testimonial-slider-2">
                                    {data.items.map((item, i) =>
                                        <div className="item" key={i}>
                                            <div className="media text-center">
                                                <div className="media-left">
                                                    <img
                                                        src={publicUrl + item.clientimg}
                                                        alt="client"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <p>{item.content}</p>
                                                    <h6>{item.client}</h6>
                                                    <span>{item.designation}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Testimonial