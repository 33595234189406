import React from 'react';

const Player = ({ name, image, description, position }) => {
  let publicUrl = process.env.PUBLIC_URL + '/';
  console.log(publicUrl)
  return (
    <div className="col-xl-6 col-lg-6 col-sm-12">
        <div className="">
          <div className=''>
            <div className="single-team">
                <div className="thumb">
                    <img src={publicUrl + image} alt={name} />
                </div>
                <h6><a href={name}>{name}</a></h6>
                <span>{position}</span>
                <p>{description}</p>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Player;
