import React from 'react';
import Navbar from '../global-components/navbar';
import Footer from "../global-components/footer";
import PlayerList from "./player-list";


class QrCodePage extends React.Component {
    render() {
        return <div>
        {/* <Navbar /> */}
        {/* <div id="home" className="header-area sbst-banner-area"></div> */}
        <PlayerList/>
        {/* <Footer /> */}
    </div>
    }
}

export default QrCodePage
