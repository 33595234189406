const playersData = [
  {
    id: 1,
    name: 'Kenf',
    position: 'Golman',
    image: 'assets/img/soccer/kenf.png',
    description: 'Momčadi daje mir i sigurnost, jer svoju poziciju shvaća k’o dužnost. O golmanskim sposobnostima ne treba puno govoriti, jer na penalu ce stranu uvijek pogoditi.'
  },
  {
    id: 2,
    name: 'Mališa',
    position: 'Može pomoći',
    image: 'assets/img/soccer/malisa.jpeg',
    description: 'Dolazi iz Pazina, sitan i dinamitan, za ovu momčad izričito bitan. Kažu neki da je otrov za žene, kada on igra protivniku loše krene.'
  },
  {
    id: 3,
    name: 'Garo',
    position: 'Kapetan',
    image: 'assets/img/soccer/josip.png',
    description: 'Krasi ga elegantnost i brzo mijenja smijer, kada ga gledaš na terenu pitaš se koja je ovo zvijer? Garo ga zovemo nekako od milja, a loptu uvijek uputi do cilja.'
  },
  {
    id: 4,
    name: 'Caki',
    position: 'Stup obrane',
    image: 'assets/img/soccer/caki.png',
    description: 'On je naš stup obrane, jer svaku tekmu odigra bez mane. Pravovremen i uvijek tamo gdje treba biti, češće zna i gol zabiti.'
  },
  {
    id: 5,
    name: 'Poki',
    position: 'Pivot',
    image: 'assets/img/soccer/poki.png',
    description: 'Prijatelj Poki naša je dika, u duelu jači je i od bika. Kada loptu primi znaš da igra pivota, igrat s njim prava je divota!'
  },
  {
    id: 6,
    name: 'Marac',
    position: 'Španer',
    image: 'assets/img/soccer/marko.png',
    description: 'Svoje prve korake u futsalu tek sad radi, može igrat prema naprijed i pozadi. Visina i desna noga njegovi su aduti ali zbog klizečeg cesto dobije žuti.'
  },
  {
    id: 7,
    name: 'Danči',
    position: 'Lijeva noga',
    image: 'assets/img/soccer/daniel.png',
    description: 'On je potomak naše akademije, sa starijim bratom ima neke kemije. Preslika našeg iskusnog kapetana, samo ona iz mlađih dana. Odličan je na krilu a može biti i pivot, uvijek protivniku zagorča život.'
  },
  {
    id: 8,
    name: 'Vito',
    position: 'Bek',
    image: 'assets/img/soccer/vito.png',
    description: 'Na odmoru najrađe pije kavu kod Željka, a za vrijeme tekme je na poziciji beka. Najviše utakmica u prvoj ligi ima pa svoje iskustvo uvijek podijeli svima.'
  },
  {
    id: 9,
    name: 'Brada',
    position: 'Ozljeđen',
    image: 'assets/img/soccer/branimir.png',
    description: 'Niti ozljede ga spriječile nisu da bude sa nama, gdje god on dođe uvijek je galama. Njegovo navijanje čut ce pola grada, kada se zadere dubokim glasom naš Brada.'
  },
  {
    id: 10,
    name: 'Baki',
    position: 'Drugi bek',
    image: 'assets/img/soccer/baki.png',
    description: 'Okretnost i brzina ovog dečka krasi, pa se tako uvijek nađe u šansi. postizanje golova nije mu strano, jer ih s guštom trpa neprestano.'
  },
  {
    id: 11,
    name: 'Viđo',
    position: 'Fizioterapeut',
    image: 'assets/img/soccer/vidak.png',
    description: 'Došao je kao pojačanje u naš tim, ne igra futsal ali ima stisak fin. S našim fizioterapeutom djelujemo kao klapa, to je naš Jakov Vidak šlapa!'
  },
  {
    id: 12,
    name: 'Miro',
    position: 'Na godišnjem',
    image: 'assets/img/soccer/miro.png',
    description: 'Ako sjevne a sunce je vani, znaš da Miro juri po strani. Izbačaj njegov ne može svatko, zato rezultat povećava glatko.'
  },
];

export default playersData;
