import React, {Component} from 'react';
import {Link} from 'react-scroll';

class Navbar extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let imgattr = 'logo';
        return (
            <div>
                <nav className="navbar navbar-area navbar-expand-lg nav-style-01">
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <div className="logo-wrapper mobile-logo">
                                <a href={"/"} className="logo">
                                    <img src={publicUrl + 'assets/img/prudentology.svg'} alt={imgattr}/>
                                </a>
                            </div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#Riyaqas_main_menu"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggle-icon">
                                    <span className="line"/>
                                    <span className="line"/>
                                    <span className="line"/>
                                </span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
                            <div className="logo-wrapper desktop-logo">
                                <a href="/" className="logo">
                                    <img src={publicUrl + 'assets/img/prudentology.svg'} alt={imgattr}/>
                                </a>
                            </div>
                            <ul className="navbar-nav">
                                <li>
                                    <Link activeClass="active" to={'home'} spy={true} smooth={true}
                                          duration={500}>Home</Link>
                                </li>
                                <li>
                                    <Link activeClass="active" to={'services'} spy={true} smooth={true}
                                          duration={500} offset={-100}>Services</Link>
                                </li>
                                <li>
                                    <Link activeClass="active" to={'clients'} spy={true} smooth={true}
                                          duration={500} offset={-200}>Clients</Link>
                                </li>
                                <li>
                                    <Link activeClass="active" to={'contact'} spy={true} smooth={true}
                                          duration={500}>Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}


export default Navbar