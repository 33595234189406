import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import {Link} from "react-scroll";

class Banner extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/';
        let imagealt = 'image';
        let data = sectiondata.banner;

        return <div id="home" className="header-area sbst-banner-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 offset-xl-1">
                        <div className="header-inner-details">
                            <div className="header-inner">
                                <h1
                                    className="title wow animated fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay="0s"
                                    dangerouslySetInnerHTML={{__html: data.title}}>
                                </h1>
                                <p
                                    className=" wow animated fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay="0.3s"
                                    dangerouslySetInnerHTML={{__html: data.subtitle}}>

                                </p>
                                <Link activeClass="active" to={'contact'} spy={true} smooth={true} duration={500}>
                                    <div
                                        className="btn-wrapper desktop-left padding-top-20 wow animated fadeInUp"
                                        data-wow-duration="1s"
                                        data-wow-delay="0.6s">
                                        <div className="btn btn-radius btn-red">
                                            {data.btn_text}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 offset-xl-1 hidden-sm">
                        <div className="banner-animate-thumb">
                            <div
                                className="header-img-1 wow animated fadeInRight"
                                data-wow-duration="1s"
                                data-wow-delay="0s"
                            >
                                <img src={publicUrl + data.image_url1} alt={imagealt}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Banner