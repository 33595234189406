import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './components/home';
import QrCodePage from './components/soccer/qr-code';

const App = () => {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route path="/qr-code" component={QrCodePage} />
      {/* <Route path="*" component={Home}/> */}
    </Router>
  );
};

export default App;
